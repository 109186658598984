import { useEffect, useState } from "react";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import Card from "../general/Card";

/**
 * Income and expenses overview
 *
 * @param {string} income // Income value
 * @param {string} expenses // Expenses value
 * @returns {JSX.Element}
 */
export default function IncomeExpensesWidget({ income, expenses }) {
  const [data, setData] = useState([]);
  const [profit, setProfit] = useState(0);
  const [saved, setSaved] = useState(0);

  useEffect(() => {
    const profit = Number(income) - Number(expenses);
    const percentageSaved = profit/Number(income)*100;

    setData([
      {
        name: 'Income',
        value: income,
      },
      {
        name: 'Expenses',
        value: expenses,
      },
    ]);

    setProfit(profit);
    setSaved((percentageSaved > 0 ? percentageSaved.toFixed(1) : 0));
  }, [income, expenses]);

  return (
    <div className="income-expenses-widget">
      <Card cardTitle="Income/expenses">
        <div className="data-overview">
          <div className="row">
            <div className="col-6">
              <p className="data-overview__title">Profit/loss</p>
              <h3 className={`data-overview__data ${profit > 0 ? 'profit' : 'loss'}`}>
                €{String(profit.toFixed(2)).replace(".", ",").replace("-", "")}
              </h3>
            </div>
            <div className="col-6">
              <p className="data-overview__title">Total saved</p>
              <h3 className="data-overview__data">{saved}%</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <p className="data-overview__title">Income</p>
              <h3 className="data-overview__data">€{income.replace(".", ",")}</h3>
            </div>
            <div className="col-6">
              <p className="data-overview__title">Expenses</p>
              <h3 className="data-overview__data">€{expenses.replace(".", ",")}</h3>
            </div>
          </div>
        </div>
        <div className="income-expenses-chart">
          <ResponsiveContainer width="100%" height={300}>
            <BarChart width="100%" height="100%" data={data}>
              <XAxis dataKey="name" stroke="#D9DEF2" />
              <YAxis stroke="#D9DEF2" />
              <Tooltip
                itemStyle={{color: "#D9DEF2"}}
                contentStyle={{backgroundColor: "#24222C"}}
                cursor={false}
              />
              <Bar dataKey="value" fill="#FFC745"/>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </Card>
    </div>
  );
}
