import { createContext, useState } from "react";

export const UserContext = createContext(null);

/**
 * User context provider
 *
 * @returns {JSX.Element}
 */
export default function UserProvider({ children }) {
  const [user, setUser] = useState(null);

  return (
    <UserContext.Provider value={{user, setUser}}>
      {children}
    </UserContext.Provider>
  );
}
