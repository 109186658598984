import { API_BASE_URL } from "../Constants";

/**
 * Get data from API
 */
export async function getDataFromApi(endpoint, user) {
  return await fetch(`${API_BASE_URL}/${endpoint}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${user.tokenObj.id_token}`,
    }
  })
    .then((res) => {
      return res.json();
    })
    .catch((err) => {
      console.error('Something went wrong:', err.message);
      return err;
    });
}

/**
 * Post data to API
 */
export async function postDataToApi(endpoint, body, user) {
  return await fetch(`${API_BASE_URL}/${endpoint}`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.tokenObj.id_token}`,
    }
  })
    .then((res) => {
      return res.json();
    })
    .catch((err) => {
      console.error('Something went wrong:', err.message);
      return err;
    });
}

/**
 * Update data to API
 */
export async function updateDataToApi(endpoint, id, body, user) {
  return await fetch(`${API_BASE_URL}/${endpoint}/${id}`, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.tokenObj.id_token}`,
    }
  })
    .then((res) => {
      return res.json();
    })
    .catch((err) => {
      console.error('Something went wrong:', err.message);
      return err;
    });
}

/**
 * Delete data from API
 */
export async function deleteDataFromApi(endpoint, id, user) {
  return await fetch(`${API_BASE_URL}/${endpoint}/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${user.tokenObj.id_token}`,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error('Something went wrong:', err.message);
      return err;
    });
}
