import moment from "moment";
import DataTable from "react-data-table-component";
import { customAmountSort, customDateSort } from "../../helpers/sort";
import Card from "../general/Card";
import DeleteIcon from "../icons/DeleteIcon";
import EditIcon from "../icons/EditIcon";

/**
 * Transaction overview
 *
 * @param {array} transactions // Transactions data
 *
 * @returns {JSX.Element}
 */
export default function TransactionOverview({
  transactions,
  setDeleteModalOpened,
  setUpdateModalOpened,
  setModalTransaction,
}) {
  const columns = [
    {
      name: "Date",
      selector: (row) => moment(row.date).format("D-M-YYYY"),
      sortable: true,
      sortFunction: customDateSort,
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => `€${Number(row.amount).toFixed(2).replace(".", ",")}`,
      sortable: true,
      sortFunction: customAmountSort,
      conditionalCellStyles: [
        {
          when: (row) => row.category === "Income",
          style: {
            color: "#00A524",
          },
        },
        {
          when: (row) => row.category !== "Income",
          style: {
            color: "#CD0000",
          },
        },
      ],
    },
    {
      name: "Update",
      button: true,
      cell: (row) => (
        <button
          className="transaction-overview__button"
          onClick={(e) => handleUpdate(e, row)}
        >
          <EditIcon />
        </button>
      ),
    },
    {
      name: "Delete",
      button: true,
      cell: (row) => (
        <button
          className="transaction-overview__button"
          onClick={(e) => handleDelete(e, row)}
        >
          <DeleteIcon />
        </button>
      ),
    },
  ];

  function handleDelete(e, transaction) {
    setDeleteModalOpened(true);
    setModalTransaction(transaction);
  }

  function handleUpdate(e, transaction) {
    setUpdateModalOpened(true);
    setModalTransaction(transaction);
  }

  return (
    <div className="transaction-overview">
      <Card cardTitle="Transactions">
        <DataTable
          columns={columns}
          data={transactions}
          fixedHeader={true}
          fixedHeaderScrollHeight="500px"
          defaultSortFieldId={1}
        />
      </Card>
    </div>
  );
}
