import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import Card from "../general/Card";

/**
 * Year overview widget
 *
 * @param {Array} // Year overview data
 * @returns {JSX.Element}
 */
export default function YearOverview({data}) {
  return (
    <div className="year-overview-widget">
      <Card cardTitle="Year overview">
        <ResponsiveContainer width="100%" height={300}>
          <AreaChart
            width="100%"
            height={300}
            data={data}
          >
            <XAxis
              dataKey="month"
              stroke="#D9DEF2"
            />
            <YAxis
              stroke="#D9DEF2"
            />
            <Tooltip
              contentStyle={{backgroundColor: "#24222C"}}
              cursor={false}
            />
            <Area
              type="monotone"
              dataKey="income"
              stroke="#00A524"
              fill="#00A524"
            />
            <Area
              type="monotone"
              dataKey="expenses"
              stroke="#CD0000"
              fill="#CD0000"
            />
          </AreaChart>
        </ResponsiveContainer>
      </Card>
    </div>
  );
}
