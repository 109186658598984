import Navigation from "./Navigation";
import Notification from "./Notification";

/**
 * Default layout
 *
 * @returns {JSX.Element}
 */
export default function Layout({ children }) {
  return (
    <div className="layout">
      <Navigation/>
      <div className="layout__content">
        {children}
      </div>
      <Notification/>
    </div>
  );
}
