/**
 * Page title component
 *
 * @param {string} title // The page title
 * @returns {JSX.Element}
 */
export default function PageTitle({ title }) {
  return (
    <div className="page-title">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>{ title }</h1>
          </div>
        </div>
      </div>
    </div>
  );
}
