import {
  deleteDataFromApi,
  getDataFromApi,
  updateDataToApi,
  postDataToApi,
} from "./api";

/**
 * Get transactions
 */
export async function getTransactionData(startDate, endDate, user) {
  return await getDataFromApi(`transactions?startDate=${startDate}&endDate=${endDate}&userId=${user.googleId}`, user)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

/**
 * Get year overview
 */
export async function getTransactionYearOverview(date, user) {
  return await getDataFromApi(`transactions/year-overview?date=${date}&userId=${user.googleId}`, user)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

/**
 * Get transactions categories
 */
export async function getTransactionCategoriesData(startDate, endDate, user) {
  return await getDataFromApi(`transactions/categories?startDate=${startDate}&endDate=${endDate}&userId=${user.googleId}`, user)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

/**
 * Post transaction data
 *
 * @param {array} transactionData // The transaction data.
 */
export async function postTransactionData(transactionData, user) {
  return await postDataToApi("transactions", transactionData, user)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

/**
 * Delete a transaction
 *
 * @param {number} transactionId // The transaction ID.
 * @param {object} transactionData // The transaction data.
 */
export async function updateTransactionData(transactionId, transactionData, user) {
  return await updateDataToApi("transactions", transactionId, transactionData, user)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

/**
 * Delete a transaction
 *
 * @param {number} transactionId // The transaction ID.
 */
export async function deleteTransactionData(transactionId, user) {
  return await deleteDataFromApi("transactions", transactionId, user)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
