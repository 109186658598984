import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../contexts/UserContext";
import { getTransactionYearOverview } from "../dao/transactions";

export default function useTransactionsYearOverview(date = new Date()) {
  const {user} = useContext(UserContext);

  const [transactionsYearOverview, setTransactionsYearOverview] = useState([]);
  const [transactionsLoading, setTransactionsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState("");
  const [refreshData, setRefreshData] = useState({});

  function fetchTransactionsData(date) {
    setTransactionsLoading(true);
    getTransactionYearOverview(date, user)
      .then((res) => {
        setTransactionsYearOverview(res);
      })
      .catch((err) => {
        setHasError(true);
        setError(err.message);
      })
      .finally(() => {
        setTransactionsLoading(false);
      });
  };

  useEffect(() => {
    const endDate = moment(date).endOf('month').format('YYYY-MM-DD');

    fetchTransactionsData(endDate);
  }, [refreshData, date]);

  return [transactionsYearOverview, transactionsLoading, setRefreshData, hasError, error];
}
