import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import Card from "../general/Card";

/**
 * Expenses by category
 *
 * @param {Object} // Categories object
 * @returns {JSX.Element}
 */
export default function ExpensesByCategoryWidget({ categories }) {
  return (
    <div className="expenses-category-widget">
      <Card cardTitle="Expenses by category">
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            width="100%"
            height="100%"
            data={categories}
            layout="vertical"
          >
            <YAxis
              type="category"
              stroke="#D9DEF2"
              dataKey="category"
              axisLine={false}
              tick={{ fontSize: "10px", width: "50px", wordWrap: "break-word" }}
              />
            <XAxis
              type="number"
              dataKey="amount"
              stroke="#D9DEF2"
              axisLine={false}
              tick={false}
            />
            <Tooltip
              itemStyle={{color: "#D9DEF2"}}
              contentStyle={{backgroundColor: "#24222C"}}
              cursor={false}
            />
            <Bar
              dataKey="amount"
              fill="#FFC745"
              barSize={10}
              radius={15}
              background={{ fill: "#24222C", radius: 15 }}
            />
          </BarChart>
        </ResponsiveContainer>
      </Card>
    </div>
  );
}
