export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export const INCOME = 'Income';
export const EXPENSE = 'Expense';
export const OTHER_INCOME = 'Other income';
export const OTHER_EXPENSE = 'Other expense';
export const TRANSACTION_CATEGORIES = [
  {
    category: 'Income',
    subCategories: [
      'Work',
      'Cash back',
      'Other income',
    ],
  },
  {
    category: 'Expense',
    subCategories: [
      'Monthly expenses',
      'Food & groceries',
      'Personal development',
      'Selfcare',
      'Car',
      'Travel',
      'Entertainment',
      OTHER_EXPENSE,
    ],
  },
];
export const FileTypes = {
  CSV: "CSV",
  MT940: "MT-940",
};
