import { DateTime } from "luxon";

function MT_940(props) {
    const {onProcessedData} = props;

    const onFileSelected = (e) => {
        e.preventDefault()
        const reader = new FileReader()
        reader.onload = async (e) => {
            const text = (e.target.result);
            const dataLines  = _splitAndNormalize(text);
            const tagLines   = _parseLines(dataLines);
            const data   = _convertLines(tagLines);
            onProcessedData(data);
        };
        reader.readAsText(e.target.files[0])
    };

    const _convertLines = (_fileRows) => {
        const rows = [];
        for (let i = 0; i < _fileRows.length; i = i + 2) {
            const {name, description} = _fileRows[i + 1];
            const {date, debitCredit, price} = _fileRows[i];
            rows.push(
                {
                    name,
                    description,
                    date,
                    debitCredit,
                    price,
                }
            );
        }
        return rows;
    };

    /**
     * Split text into lines, replace clutter, remove empty lines ...
     * @private
     */
    const _splitAndNormalize = (data) => {
        return data
            .split(/\r?\n/)
            .filter(line => !!line && line !== '-');
    }

    /**
     * Convert lines into separate tags
     * @private
     */
    const _parseLines = (lines) => {
        return lines.filter(line => {
            return line.indexOf(':86:') > -1 || line.indexOf(':61:') > -1
        }).map(line => {
            if (line.indexOf(':86:') > -1){
                // NOTE: account information line
                const name = line.split('/NAME/')[1].split('/REMI/')[0];
                const description = line.split('/REMI/')[1];
                return {
                    name,
                    description
                };
            } else if(line.indexOf(':61:') > -1) {
                // NOTE: statement line (date, amount, credit/debit)
                const date = DateTime.fromFormat(line.slice(4,10), "yyMMdd");
                const debitCredit = line.slice(14,15) === 'D' ? '-' : '+';
                const price = line.slice(15,20);
                return {
                    date: date.toISODate(),
                    debitCredit,
                    price: price.replaceAll(/[^0-9,.]/ig, ''),
                };
            }
            return line;
        });
    }

    return (
      <div className="col-12 col-lg-6">
        <label for="file-selector">
          <p>Select MT-940 file:</p>
        </label>
        <input
          type="file"
          accept=".sta"
          name="file-selector"
          id="file-selector"
          onChange={onFileSelected}
        />
      </div>
    );
}

export default MT_940;
