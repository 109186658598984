import { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext.js';

/**
 * The main navigation
 *
 * @returns {JSX.Element}
 */
export default function ProtectedRoute({redirectPath = '/', children}) {
  const {user} = useContext(UserContext);

  if (!user) {
    return <Navigate to={redirectPath} replace/>;
  }

  return children ? children : <Outlet/>;
}
