import { createContext, useEffect, useState } from "react";

export const NotificationContext = createContext(null);

/**
 * Notification context provider
 *
 * @returns {JSX.Element}
 */
export default function NotificationProvider({ children }) {
  const [message, setMessage] = useState('');
  const [isConfirmation, setIsConfirmation] = useState(true);

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setMessage('');
      }, 2000);
    }
  }, [message]);

  return (
    <NotificationContext.Provider value={{message, setMessage, isConfirmation, setIsConfirmation}}>
      {children}
    </NotificationContext.Provider>
  );
}
