import { useEffect } from "react";
import CloseIcon from "../icons/CloseIcon";

/**
 * Default modal
 *
 * @returns {JSX.Element}
 */
export default function Modal({ modalTitle, setModalOpened, children }) {
  function closeModal() {
    document.body.style.overflow = "";
    setModalOpened(false);
  }

  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal__header">
          <h3>{modalTitle}</h3>
          <div className="modal__header--close" onClick={closeModal}>
            <CloseIcon />
          </div>
        </div>
        {children}
      </div>
    </div>
  );
}
