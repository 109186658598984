import { useContext } from "react";
import { GoogleLogout } from "react-google-login";
import { GOOGLE_CLIENT_ID } from "../../Constants";
import { UserContext } from "../../contexts/UserContext";

/**
 * Logout button
 *
 * @returns {JSX.Element}
 */
export default function LogoutButton() {
  const {setUser} = useContext(UserContext);
  const clientId = GOOGLE_CLIENT_ID;

  const logOut = () => {
    setUser(null);
    window.location.replace('/');
  };

  return (
    <GoogleLogout
        clientId={clientId}
        buttonText="Log out"
        onLogoutSuccess={logOut}
      />
  );
}
