import moment from "moment";

export function customDateSort(dateA, dateB) {
  const a = moment(dateA.date).unix();
  const b = moment(dateB.date).unix();

  if (a > b) {
    return 1;
  } else if (b > a) {
    return -1;
  } else {
    return 0;
  }
}

export function customAmountSort(amountA, amountB) {
  const a = Number(amountA.amount);
  const b = Number(amountB.amount);

  if (a > b) {
    return 1;
  } else if (b > a) {
    return -1;
  } else {
    return 0;
  }
}
