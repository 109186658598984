import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext.js';
import AddIcon from '../icons/AddIcon.js';
import HomeIcon from '../icons/HomeIcon.js';
import ProfileIcon from '../icons/ProfileIcon.js';

/**
 * The main navigation
 *
 * @returns {JSX.Element}
 */
export default function Navigation() {
  const {user} = useContext(UserContext);

  return (
    <div className="navigation">
      <div className='navigation__pages'>
        <NavigationItem url="/dashboard">
          <HomeIcon/>
        </NavigationItem>
        {user &&
          <NavigationItem url="/add-transaction">
            <AddIcon/>
          </NavigationItem>
        }
      </div>
      {user &&
        <NavigationItem url="/profile">
          <ProfileIcon/>
        </NavigationItem>
      }
    </div>
  );
}

/**
 * Navigation item
 *
 * @param {string} url // URL to link to
 * @returns {JSX.Element}
 */
function NavigationItem({ url, children }) {
  const location = useLocation();

  return(
    <div className="navigation-item">
      <Link to={ url } className={location.pathname === url ? 'active' : ''}>
        {children}
      </Link>
    </div>
  );
}
