import PageTitle from "../components/general/PageTitle";
import ImportTransactions from "../components/widgets/ImportTransactions";

/**
 * Add data page
 *
 * @returns {JSX.Element}
 */
export default function AddData() {
  return (
    <div className="add-transaction">
      <PageTitle title="Add data" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <ImportTransactions/>
          </div>
        </div>
      </div>
    </div>
  );
}
