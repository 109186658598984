import { useContext, useEffect } from "react";
import { gapi } from "gapi-script";
import GoogleLogin from "react-google-login";
import { UserContext } from "../../contexts/UserContext";
import { GOOGLE_CLIENT_ID } from "../../Constants";

/**
 * Login button
 *
 * @returns {JSX.Element}
 */
export default function LoginButton() {
  const {setUser} = useContext(UserContext);
  const clientId = GOOGLE_CLIENT_ID;

  useEffect(() => {
    const initClient = () => {
        gapi.client.init({
        clientId: clientId,
        scope: 'profile',
      });
    };
    gapi.load('client:auth2', initClient);
  });

  const onSuccess = (res) => {
    setUser(res);
  };

  const onFailure = (err) => {
      console.error('Login failed:', err);
  };

  return (
    <GoogleLogin
      clientId={clientId}
      buttonText="Sign in with Google"
      onSuccess={onSuccess}
      onFailure={onFailure}
      cookiePolicy={'single_host_origin'}
      isSignedIn={true}
    />
  );
}
