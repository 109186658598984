import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../contexts/UserContext";
import { getTransactionCategoriesData } from "../dao/transactions";

export default function useTransactionsCategories(date = new Date()) {
  const {user} = useContext(UserContext);

  const [transactionsCategories, setTransactionsCategories] = useState([]);
  const [transactionsLoading, setTransactionsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState("");
  const [refreshData, setRefreshData] = useState({});

  function fetchTransactionsData(startDate, endDate) {
    setTransactionsLoading(true);
    getTransactionCategoriesData(startDate, endDate, user)
      .then((res) => {
        setTransactionsCategories(res.categories);
      })
      .catch((err) => {
        setHasError(true);
        setError(err.message);
      })
      .finally(() => {
        setTransactionsLoading(false);
      });
  };

  useEffect(() => {
    const startDate = moment(date).startOf('month').add(-1, 'days').format('YYYY-MM-DD');
    const endDate = moment(date).endOf('month').format('YYYY-MM-DD');

    fetchTransactionsData(startDate, endDate);
  }, [refreshData, date]);

  return [transactionsCategories, transactionsLoading, setRefreshData, hasError, error];
}
