import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import { NotificationContext } from "../../contexts/NotificationContext";
import { deleteTransactionData } from "../../dao/transactions";
import Modal from "../general/Modal";

/**
 * Delete transaction modal
 *
 * @returns {JSX.Element}
 */
export default function DeleteTransactionModal({
  transaction,
  setModalOpened,
}) {
  const {user} = useContext(UserContext);
  const {setMessage, setIsConfirmation} = useContext(NotificationContext);

  /**
   * Close the modal
   */
  function closeModal() {
    document.body.style.overflow = "";
    setModalOpened(false);
  }

  /**
   * Delete transaction
   */
  async function deleteTransaction() {
    await deleteTransactionData(transaction.id, user)
      .then(() => {
        document.body.style.overflow = "";
        setModalOpened(false);
        setIsConfirmation(true);
        setMessage('Transaction deleted');
      })
      .catch(() => {
        setIsConfirmation(false);
        setMessage('Something went wrong');
      });
  }

  return (
    <div className="delete-modal">
      <Modal modalTitle="Delete transaction" setModalOpened={setModalOpened}>
        <div className="delete-modal__wrapper">
          <table>
            <tbody>
              <tr>
                <td>Title:</td>
                <td>{transaction.title}</td>
              </tr>
              <tr>
                <td>Description:</td>
                <td>{transaction.description}</td>
              </tr>
              <tr>
                <td>Category:</td>
                <td>{transaction.subCategory}</td>
              </tr>
              <tr>
                <td>Amount:</td>
                <td>€{transaction.amount}</td>
              </tr>
            </tbody>
          </table>
          <div>
            <h5>Are you sure you want to delete this transaction?</h5>
            <div className="delete-modal__wrapper--buttons">
              <button className="btn btn-secondary" onClick={closeModal}>
                Cancel
              </button>
              <button className="btn btn-warning" onClick={deleteTransaction}>
                Delete
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
