import { useContext, useState } from "react";
import { EXPENSE, FileTypes, INCOME, OTHER_EXPENSE, OTHER_INCOME, TRANSACTION_CATEGORIES } from "../../Constants";
import { UserContext } from "../../contexts/UserContext";
import { NotificationContext } from "../../contexts/NotificationContext";
import { postTransactionData } from "../../dao/transactions";
import Card from "../general/Card";
import DeleteIcon from "../icons/DeleteIcon";
import CSV from "../transactions/import/csv";
import MT_940 from "../transactions/import/mt-940";

/**
 * Import transactions widget
 *
 * @returns {JSX.Element}
 */
export default function ImportTransactions() {
  const columns = [
    { name: "Datum", index: null },
    { name: "Titel", index: null },
    { name: "Omschrijving", index: null },
    { name: "Bedrag", index: null },
  ];

  const {user} = useContext(UserContext);
  const {setMessage, setIsConfirmation} = useContext(NotificationContext);

  const [fileType, setFileType] = useState(FileTypes.CSV);
  const [transactionData, setTransactionData] = useState([]);

  function onProcessedData(data) {
    data.forEach((transaction) => {
      if (transaction.debitCredit === "+") {
        transaction.category = INCOME;
        transaction.subCategory = OTHER_INCOME;
      } else if (transaction.debitCredit === "-") {
        transaction.category = EXPENSE;
        transaction.subCategory = OTHER_EXPENSE;
      } else {
        transaction.category = null;
      }

      transaction.userId = user.googleId;
    });

    setTransactionData(data);
  }

  function onCategoryChange(value, index) {
    let tempTransactionData = [...transactionData];
    tempTransactionData[index].subCategory = value;

    setTransactionData(tempTransactionData);
  }

  function deleteTransaction(index) {
    let tempTransactionData = [...transactionData];
    tempTransactionData.splice(index, 1);

    setTransactionData(tempTransactionData);
  }

  async function onSubmit() {
    if (transactionData.length) {
      await postTransactionData(transactionData, user)
        .then(() => {
          setTransactionData([]);
          setIsConfirmation(true);
          setMessage('Transactions imported');
        })
        .catch(() => {
          setIsConfirmation(false);
          setMessage('Something went wrong');
        });
    }
  }

  return (
    <div className="import-transactions-widget">
      <Card cardTitle="Import transactions">
        <div className="row import-options">
          <div className="col-12 col-lg-6">
            <label for="fileType">
              <p>Import type:</p>
            </label>
            <select
              onChange={(e) => {
                setFileType(e.target.value)
              }}
              value={fileType}
              id="fileType"
            >
              {Object.keys(FileTypes).map((key, index) => {
                return (
                  <option key={index}>
                    {FileTypes[key]}
                  </option>
                );
              })}
            </select>
          </div>
          {fileType === FileTypes.MT940 && (
            <MT_940 columns={columns} onProcessedData={onProcessedData} />
          )}
          {fileType === FileTypes.CSV && (
            <CSV
              columns={columns}
              onProcessedData={onProcessedData}
            />
          )}
        </div>

        {transactionData.length > 0 && (
          <div>
            <div className="row">
              <div className="col-12">
                <div className="data-overview">
                  <table>
                    <thead>
                      <tr>
                        <th>
                          <p>Date</p>
                        </th>
                        <th>
                          <p>Name</p>
                        </th>
                        <th>
                          <p>Description</p>
                        </th>
                        <th>
                          <p>Price</p>
                        </th>
                        <th>
                          <p>Category</p>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactionData.map((transaction, i) => {
                        return (
                          <tr key={`${transaction.title}_${transaction.date}`}>
                            <td>
                              <p>{transaction.date}</p>
                            </td>
                            <td>
                              <p>{transaction.title}</p>
                            </td>
                            <td>
                              <p>{transaction.description}</p>
                            </td>
                            <td>
                              <p className={transaction.category === EXPENSE ? 'expense' : 'income'}>€{transaction.amount}</p>
                            </td>
                            <td>
                              <select
                                value={transaction.subCategory}
                                onChange={(e) => {
                                  onCategoryChange(e.target.value, i);
                                }}
                              >
                                {TRANSACTION_CATEGORIES.map((category) => {
                                  return (
                                    <optgroup key={category.category} label={category.category}>
                                      {category.subCategories.map((subCategorie) => {
                                        return (
                                          <option key={subCategorie} value={subCategorie}>
                                            {subCategorie}
                                          </option>
                                        );
                                      })}
                                    </optgroup>
                                  );
                                })}
                              </select>
                            </td>
                            <td>
                              <div onClick={() => deleteTransaction(i)}>
                                <DeleteIcon/>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <button className="btn btn-primary" onClick={onSubmit}>
                  Import
                </button>
              </div>
            </div>
          </div>
        )}
      </Card>
    </div>
  );
}
