/**
 * Default layout
 *
 * @param {string} buttonText // Text to display in the button
 * @param {function} handleClick // Onclick function
 * @returns {JSX.Element}
 */
export default function Button({ buttonText, handleClick, type = 'primary' }) {
  return (
    <button className={`btn btn-${type}`} onClick={handleClick}>
      {buttonText}
    </button>
  );
}
