import { Route, Routes } from "react-router-dom";
import Layout from "./components/general/Layout";
import Dashboard from "./pages/Dashboard";
import AddData from "./pages/AddData";
import UserProvider from "./contexts/UserContext";
import Profile from "./pages/Profile";
import ProtectedRoute from "./components/general/ProtectedRoute";
import Home from "./pages/Home";
import NotificationProvider from "./contexts/NotificationContext";

export default function App() {
  return (
    <UserProvider>
      <NotificationProvider>
        <Layout>
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route element={<ProtectedRoute />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/add-transaction" element={<AddData />} />
              <Route path="/profile" element={<Profile />} />
            </Route>
          </Routes>
        </Layout>
      </NotificationProvider>
    </UserProvider>
  );
}
