import { useState } from "react";

function CSV(props) {
  const { columns: initialColumns, onProcessedData } = props;

  const [tableColumns, setTableColumns] = useState(initialColumns);
  const [seperator] = useState(",");
  const [fileRows, setFileRows] = useState([]);
  const [fileColumns, setFileColumns] = useState([]);

  const onFileSelected = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    setTableColumns(
      tableColumns.map((column) => {
        return {
          ...column,
          index: null,
        };
      })
    );
    reader.onload = async (e) => {
      const text = e.target.result;
      const rows = text.split(/(?:\r\n|\n)+/).filter((el) => el.length !== 0);
      setFileColumns(rows.splice(0, 1)[0].split(seperator));
      setFileRows(rows);
    };
    reader.readAsText(e.target.files[0]);
  };

  const onColumnSelection = (column, e) => {
    column.index = Number(e.target.value);
    if (tableColumns.filter((column) => column.index === null).length === 0) {
      processRows([]);
    }
  };

  const processRows = (rows) => {
    const columnConfig = tableColumns.reduce((config, column) => {
      return {
        ...config,
        [column.name]: column.index,
      };
    }, {});
    // eslint-disable-next-line no-useless-escape
    let valuesRegExp = /(?:\"([^\"]*(?:\"\"[^\"]*)*)\")|([^\",]+)/g;
    for (let i = 0; i < fileRows.length; i++) {
      let element = {};
      let j = 0;

      let matches = [];
      // eslint-disable-next-line no-cond-assign
      while ((matches = valuesRegExp.exec(fileRows[i]))) {
        let value = matches[1] || matches[2];
        if (value) {
          // eslint-disable-next-line no-useless-escape
          value = value.replace(/\"\"/g, '"');
        }

        element[fileColumns[j]] = value;
        j++;
      }

      rows.push(element);
    }
    onProcessedData(
      rows.map((row) => {
        row = Object.values(row);
        const price = row[columnConfig["Bedrag"]];
        return {
          title: row[columnConfig["Titel"]],
          description: row[columnConfig["Omschrijving"]],
          date: row[columnConfig["Datum"]],
          debitCredit: price.indexOf("+") > -1 ? "+" : "-",
          amount: price.replace(/[-+.]/g, "").replace(',', '.'),
        };
      })
    );
  };

  return (
    <>
      <div className="col-12 col-lg-6">
        <label for="file-selector">
          <p>Select CSV file:</p>
        </label>
        <input
          type="file"
          accept=".csv"
          name="file-selector"
          id="file-selector"
          onChange={onFileSelected}
        />
      </div>

      {fileColumns.length > 0 &&
        tableColumns.map((column) => {
          return (
            <div key={column.name} className="col-12 col-lg-6">
              <label for={column.name}>
                <p>{column.name}:</p>
              </label>
              <select id={column.name} onChange={(e) => onColumnSelection(column, e)}>
                <option value="">Select Column</option>
                {fileColumns.map((fileColumn, index) => {
                  return (
                    <option key={column.name + fileColumn} value={index}>
                      {fileColumn.replace(/"/g, "")}
                    </option>
                  );
                })}
              </select>
            </div>
          );
        })
      }
    </>
  );
}

export default CSV;
