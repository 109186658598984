import { BallTriangle } from "react-loading-icons";

/**
 * Default layout
 *
 * @returns {JSX.Element}
 */
export default function Loading() {
  return (
    <div className="loading">
      <BallTriangle stroke="#2763E8"/>
    </div>
  );
}
