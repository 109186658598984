import { useContext, useEffect, useState } from "react";
import Loading from "../components/general/Loading";
import PageTitle from "../components/general/PageTitle";
import TransactionOverview from "../components/widgets/TransactionOverview";
import useTransactions from "../hooks/useTransactions";
import DeleteTransactionModal from "../components/modals/DeleteTransactionModal";
import UpdateTransactionModal from "../components/modals/UpdateTransactionModal";
import ReactDatePicker from "react-datepicker";
import { UserContext } from "../contexts/UserContext";
import LoginButton from "../components/general/LoginButton";
import IncomeExpensesWidget from "../components/widgets/IncomeExpensesWidget";
import useTransactionsCategories from "../hooks/useTransactionsCategories";
import ExpensesByCategoryWidget from "../components/widgets/ExpensesByCategoryWidget";
import YearOverview from "../components/widgets/YearOverview";
import useTransactionsYearOverview from "../hooks/useTransactionsYearOverview";

/**
 * Dashboard page
 *
 * @returns {JSX.Element}
 */
export default function Dashboard() {
  const {user} = useContext(UserContext);
  // Default the month to one month prior
  const [month, setMonth] = useState(new Date().setMonth(new Date().getMonth() - 1));
  const [transactions, income, expenses, transactionsLoading, setRefreshData] = useTransactions(month);
  const [transactionsCategories] = useTransactionsCategories(month);
  const [transactionsYearOverview] = useTransactionsYearOverview(month);
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);
  const [updateModalOpened, setUpdateModalOpened] = useState(false);
  const [modalTransaction, setModalTransaction] = useState({});

  useEffect(() => {
    if (!deleteModalOpened && !updateModalOpened) {
      setRefreshData({});
    }
  }, [deleteModalOpened, updateModalOpened, setRefreshData]);

  // Show login button when user isn't signed in.
  if (!user) {
    return <LoginButton/>;
  }

  if (transactionsLoading) {
    return <Loading />;
  }

  return (
    <>
      {deleteModalOpened && (
        <DeleteTransactionModal
          setModalOpened={setDeleteModalOpened}
          transaction={modalTransaction}
        />
      )}

      {updateModalOpened && (
        <UpdateTransactionModal
          setModalOpened={setUpdateModalOpened}
          transaction={modalTransaction}
        />
      )}

      <div className="dashboard">
        <div className="dashboard__header">
          <PageTitle title="Dashboard" />
          <ReactDatePicker
            selected={month}
            onChange={(date) => setMonth(date)}
            dateFormat="MMMM yyyy"
            showMonthYearPicker
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8">
              <TransactionOverview
                transactions={transactions}
                setDeleteModalOpened={setDeleteModalOpened}
                setUpdateModalOpened={setUpdateModalOpened}
                setModalTransaction={setModalTransaction}
              />
            </div>
            {transactions.length &&
              <>
                <div className="col-12 col-lg-4">
                  <IncomeExpensesWidget income={income} expenses={expenses}/>
                </div>
                <div className="col-12 col-lg-4">
                  <ExpensesByCategoryWidget categories={transactionsCategories}/>
                </div>
                <div className="col-12 col-lg-4">
                  <YearOverview data={transactionsYearOverview}/>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </>
  );
}
