import { useContext, useEffect, useState } from "react";
import { TRANSACTION_CATEGORIES } from "../../Constants";
import { UserContext } from "../../contexts/UserContext";
import { NotificationContext } from "../../contexts/NotificationContext";
import { updateTransactionData } from "../../dao/transactions";
import Modal from "../general/Modal";

/**
 * Update transaction modal
 *
 * @returns {JSX.Element}
 */
export default function UpdateTransactionModal({
  transaction,
  setModalOpened,
}) {
  const {user} = useContext(UserContext);
  const {setMessage, setIsConfirmation} = useContext(NotificationContext);

  const [transactionTitle, setTransactionTitle] = useState(transaction.title);
  const [transactionDescription, setTransactionDescription] = useState(
    transaction.description
    );
  const [transactionCategory, setTransactionCategory] = useState(transaction.category);
  const [transactionSubCategory, setTransactionSubCategory] = useState(transaction.subCategory || '-');

  useEffect(() => {
    const getCategory = TRANSACTION_CATEGORIES.find((element) => {
      return element.subCategories.find((subCategories) => {
        return transactionSubCategory === subCategories;
      });
    });

    setTransactionCategory(getCategory.category);
  }, [transactionSubCategory]);

  /**
   * Close the modal
   */
  function closeModal() {
    document.body.style.overflow = "";
    setModalOpened(false);
  }

  /**
   * Delete transaction
   */
  async function updateTransaction() {
    await updateTransactionData(
      transaction.id,
      {
        title: transactionTitle,
        description: transactionDescription,
        amount: transaction.amount,
        category: transactionCategory,
        subCategory: transactionSubCategory,
        date: transaction.date,
      },
      user,
    ).then(() => {
      document.body.style.overflow = "";
      setModalOpened(false);
      setIsConfirmation(true);
      setMessage('Transaction updated');
    })
    .catch(() => {
      setIsConfirmation(false);
      setMessage('Something went wrong');
    });
  }

  return (
    <div className="update-modal">
      <Modal modalTitle="Update transaction" setModalOpened={setModalOpened}>
        <div className="update-modal__wrapper">

          <div>
            <label className="update-modal__wrapper--input">
              Title:
              <input
                type="text"
                value={transactionTitle}
                onChange={(e) => setTransactionTitle(e.target.value)}
              />
            </label>

            <label className="update-modal__wrapper--input">
              Description:
              <input
                type="text"
                value={transactionDescription}
                onChange={(e) => setTransactionDescription(e.target.value)}
              />
            </label>

            <label className="update-modal__wrapper--input">
              Category:
              <select
                value={transactionSubCategory}
                onChange={(e) => setTransactionSubCategory(e.target.value)}
              >
                {TRANSACTION_CATEGORIES.map((category) => {
                  return (
                    <optgroup key={category.category} label={category.category}>
                      {category.subCategories.map((subCategorie) => {
                        return (
                          <option key={subCategorie} value={subCategorie}>
                            {subCategorie}
                          </option>
                        );
                      })}
                    </optgroup>
                  );
                })}
              </select>
            </label>
          </div>

          <div>
            <h5>Are you sure you want to update this transaction?</h5>
            <div className="delete-modal__wrapper--buttons">
              <button className="btn btn-secondary" onClick={closeModal}>
                Cancel
              </button>
              <button className="btn btn-primary" onClick={updateTransaction}>
                Save
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
