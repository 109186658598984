import { useContext } from "react";
import { NotificationContext } from "../../contexts/NotificationContext";
import CheckIcon from "../icons/CheckIcon";
import CloseIcon from "../icons/CloseIcon";

/**
 * Notification component
 *
 * @returns {JSX.Element}
 */
 export default function Notification() {
  const {message, isConfirmation} = useContext(NotificationContext);

  if (!message) {
    return;
  }

  return (
    <div className="notification">
      {isConfirmation ?
        <CheckIcon fillColor="#00A524"/>
        :
        <CloseIcon fillColor="#CD0000"/>
      }
      <p className="notification__message">{message}</p>
    </div>
  );
}
