import Button from "../components/general/Button";
import LogoutButton from "../components/general/LogoutButton";

/**
 * Profile page
 *
 * @returns {JSX.Element}
 */
export default function Profile() {

  function deleteTransactions() {
    console.log('Delete all data');
  }

  return (
    <div className="profile">
      <LogoutButton/>
      <div className="profile__delete">
        <label>Delete all transaction data?</label>
        <Button buttonText="Delete" type="warning" handleClick={deleteTransactions}/>
      </div>
    </div>
  );
}
