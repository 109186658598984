/**
 * Default card
 *
 * @param {string} cardTitle // Title for the card
 * @returns {JSX.Element}
 */
 export default function Card({ cardTitle, children }) {
  return (
    <div className="card">
      <h3>{cardTitle}</h3>
      {children}
    </div>
  );
}
