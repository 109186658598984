import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import LoginButton from "../components/general/LoginButton";
import { Navigate } from "react-router-dom";

/**
 * Homepage
 *
 * @returns {JSX.Element}
 */
export default function Home() {
  const {user} = useContext(UserContext);

  // Redirect user to dashboard if signed in
  if (user) {
    return <Navigate to='/dashboard' replace/>;
  }

  // Show login button when user isn't signed in.
  return <LoginButton/>;
}
